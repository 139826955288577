import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import UploadInput from '../../common/components/UploadInput';
import FileWrapper from '../../common/components/FileWrapper';

import { OptionModalStyles } from './OptionModal.styles';
import { addNewReplacement } from '../../features/afterSale/afterSaleActions';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';

const schema = yup.object({
  files: yup.object({
    1: yup.object().required('Documento è obbligatorio'),
    2: yup.object().required('Documento è obbligatorio'),
  }).required('Documenti sono obbligatori'),
  note: yup.string().required('Il campo è obbligatorio'),
});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

export function Replacement({
  quotationId, selectOption, location, document_types,
}) {
  const isOpenedByCommand = location.state !== undefined && location.state?.type === 'command'
    && location.state.data?.page === 'replacement';

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    watch,
    reset: resetForm,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    values: isOpenedByCommand ? {
      files: null,
      note: location.state.data.note ?? '',
    } : {
      files: null,
      note: '',
    },
  });

  const answers = watch();

  const fileInputHandler = (files, type_id) => {
    const file = files[0];
    let baseURL = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    reader.onload = () => {
      baseURL = reader.result;
      setValue('files', {
        ...answers.files,
        [type_id]: {
          original_filename: file.name,
          content: baseURL.replace(`data:${fileType};base64,`, ''),
          type: `data:${fileType};base64,`,
        },
      }, asInputOptions);
    };
  };

  const resetEverything = () => {
    resetForm();
    selectOption(null);
  };

  const submitData = (formData) => {
    addNewReplacement(quotationId, formData, resetEverything);
  };

  const removeFileHandler = (document_id) => {
    const tempFiles = answers.files;
    delete tempFiles[document_id];
    setValue('files', tempFiles, asInputOptions);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  return (
    <OptionModalStyles>
      <div className="option-modal-content">
        <h1 className="option-modal-title">Sostituzione</h1>
        <form onSubmit={handleSubmit(submitData)} className="report-form-container">
          <div className="row">
            <FormInputTextarea
              placeholder="Descrivi cosa hai bisogno di modificare"
              label="Note"
              registration={register('note')}
              rows="3"
              error={errors.note?.message}
              valid={isValid('note')}
            />
          </div>
          <div className="d-flex mt-4 align-items-center justify-content-center gap-2">
            {document_types.length > 0 && document_types.map((document, index) => (
              <div key={index}>
                <div hidden={answers.files?.[document.id]}>
                  <UploadInput
                    uploadHandler={(files) => fileInputHandler(files, document.id)}
                    text={document.name}
                    invalid={false}
                  />
                </div>

                <div className="general-input-error">
                  {(!Array.isArray(errors.files)) ? errors.files?.message : errors.files?.[document.id]?.message}
                </div>
                <h6 className="mt-3" hidden={!answers.files?.[document.id]}>
                  Documento caricato
                </h6>
                {answers.files?.[document.id]
                  && (
                    <FileWrapper
                      className="wide-file-wrapper me-3"
                      files={[answers.files[document.id]]}
                      removeFileAction={() => removeFileHandler(document.id)}
                    />
                  )}
              </div>
            ))}
          </div>
          <div className="option-modal-actions">
            <button
              className="option-modal-button option-modal-button-close"
              onClick={() => selectOption(null)}
            >
              Chiudi
            </button>
            <button
              type="submit"
              className="option-modal-button option-modal-button-submit"
            >
              Invia
            </button>
          </div>
        </form>
      </div>
    </OptionModalStyles>
  );
}
