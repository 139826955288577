import React, { useRef } from 'react';
import { RespCivileModal } from '../Cards/ProfessionInfoCard.styles';
import { QuoteLoader } from '../../../../screens/RcQuotes.styles';
import AdriaticCard from '../Cards/AdriaticCard';
import PrimaCard from '../Cards/PrimaCard';
import GreeniaCard from '../Cards/GreeniaCard';
import { PreventivassCardLinearAndDallbogg } from '../Cards/PreventivassCardLinearAndDallbogg';
import { PreventivassCard } from '../Cards/PreventivassCard';
import PreventivassCardNoAquista from '../../elements/quote/cards/PreventivassCardNoAquista';
import RiskCard from '../Cards/RiskCard';
import AragCard from '../Cards/AragCard';

function ModalQuotes({
  type, addingQuote, sortBy, sortQuotes, handleCurrentQuotesDisplay, currentQuotes, requestInfo, counter, quotations, requestToken, refresh, selectQuotation, guaranteesPrima, preventivas_quotes, processingQuote,
}) {
  const closeButtonRef = useRef(null);

  const closeButton = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click(); // Close the modal

      closeButtonRef.current = null;
    }
  };

  const selectQuote = (quoteId) => {
    selectQuotation(quoteId, closeButton);
  };

  return (
    <RespCivileModal className="modal fade" id={type.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{type.name}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="buttonClose" ref={closeButtonRef} />
          </div>
          <div className="modal-body">
            {addingQuote && (
              <div className="d-flex align-items-center justify-content-between flex-column">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Aggiungendo...</span>
                  </div>
                </div>
                <div className="ms-2">Aggiungendo...</div>
              </div>
            )}
            {addingQuote === false && (
              <>
                <div className="row mb-4">
                  {type.id === 'res_civile'
                    && (
                      <>
                        <div className="col-12 mb-2">
                          <div className="dropdown purchase-quote-fourth-step">
                            <button className="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Ordinato Per:
                              {' '}
                              {sortBy === 'amount' ? 'Premio' : 'Indice Di Qualità'}
                            </button>
                            <ul className="dropdown-menu mt-2">
                              <li style={{ cursor: 'pointer' }} onClick={() => sortQuotes(sortBy === 'amount' ? 'coefficente' : 'amount')}>
                                <a className="dropdown-item">{sortBy === 'amount' ? 'Indice Di Qualità' : 'Premio'}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="d-flex align-items-stretch justify-content-center gap-2 mb-3">
                          <button className={`btn purchase-quote-fifth-step ${currentQuotes === 'diretta' ? 'btn-success' : 'btn-outline-success'}`} onClick={() => handleCurrentQuotesDisplay('diretta')}>Compagnie Dirette</button>
                          <button className={`btn purchase-quote-sixth-step ${currentQuotes === 'indiretta' ? 'btn-success' : 'btn-outline-success'}`} onClick={() => handleCurrentQuotesDisplay('indiretta')}>Compagnie Indirette</button>
                          <button className={`btn purchase-quote-seventh-step ${currentQuotes === 'quotazioni' ? 'btn-success' : 'btn-outline-success'}`} onClick={() => handleCurrentQuotesDisplay('quotazioni')}>Preventivi</button>
                        </div>
                      </>
                    )}
                  {(type.id === 'res_civile' && requestInfo.product_id == 1 && requestInfo.with_preventivas === null) && (
                    <div className="d-flex align-items-center justify-content-center mb-2 preventivas-counter">
                      <QuoteLoader>
                        {counter}
                      </QuoteLoader>
                      {' '}
                      <span className="ms-2">Recuperando più preventivi...</span>
                    </div>
                  )}

                </div>
                <div className="row purchase-quote-eight-step">
                  {addingQuote === false && (quotations && quotations[type.id] && type.id === 'res_civile') && (currentQuotes === 'diretta' || currentQuotes === 'indiretta') ? quotations[type.id].map((quote, index) => (
                    currentQuotes === 'diretta' ? (
                      <React.Fragment key={index}>
                        {quote.company_id === 1
                          && (
                            <div className="col-12 mb-2">
                              <AdriaticCard requestInfo={requestInfo} quotation={quote} selectQuotation={selectQuote} />
                            </div>
                          )}
                        {quote.company_id === 2
                          && (
                            <div className="col-12 mb-2">
                              <PrimaCard requestToken={requestToken} requestInfo={requestInfo} quotation={quote} saved_quote={quote.saved_quotation} guaranteesPrima={guaranteesPrima} refresh={refresh} selectQuotation={selectQuote} />
                            </div>
                          )}
                        {quote.company_id === 11
                          && (
                            <div className="col-12 mb-2">
                              <GreeniaCard requestInfo={requestInfo} quotation={quote} refresh={refresh} selectQuotation={selectQuote} />
                            </div>
                          )}
                        {quote.company_id === 12
                          && (
                            <div className="col-12 mb-2">
                              <GreeniaCard requestInfo={requestInfo} quotation={quote} refresh={refresh} selectQuotation={selectQuote} />
                            </div>
                          )}
                        {quote.company_id === 13
                          && (
                            <div className="col-12 mb-2">
                              <GreeniaCard requestInfo={requestInfo} quotation={quote} refresh={refresh} selectQuotation={selectQuote} />
                            </div>
                          )}
                        {quote.company_id === 14
                          && (
                            <div className="col-12 mb-2">
                              <GreeniaCard requestInfo={requestInfo} quotation={quote} refresh={refresh} selectQuotation={selectQuote} />
                            </div>
                          )}
                        {(quote.company_id === 9 || quote.company_id === 10)
                          && (
                            <div className="col-12 mb-2">
                              <PreventivassCardLinearAndDallbogg requestInfo={requestInfo} quotation={quote} refresh={refresh} selectQuotation={selectQuote} />
                            </div>
                          )}
                        {quote.added_manually === 1
                          && (
                            <div className="col-12 mb-2">
                              <PreventivassCard requestInfo={requestInfo} quotation={quote} refresh={refresh} selectQuotation={selectQuote} />
                            </div>
                          )}
                      </React.Fragment>
                    ) : currentQuotes === 'indiretta' ? (
                      <React.Fragment key={index}>
                        {quote.company_id === null && quote.added_manually == 0
                          && (
                            <div className="col-12 mb-2">
                              <PreventivassCard requestInfo={requestInfo} quotation={quote} refresh={refresh} selectQuotation={selectQuote} />
                            </div>
                          )}
                      </React.Fragment>
                    ) : ''
                  )) : ''}
                  {
                    currentQuotes === 'quotazioni'
                      ? preventivas_quotes.map((quote, index) => (
                        <div className="col-12 mb-2" key={index}>
                          <PreventivassCardNoAquista requestInfo={requestInfo} quotation={quote} />
                        </div>
                      )) : ''
                  }
                  {currentQuotes === 'quotazioni' && ((quotations === null || quotations[type.id] === null) && type.id === 'res_civile') ? <h5 className="fw-bold">Nessuna Quotazione Trovata</h5> : ''}

                  {type.id === 'assistenza_stradale' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote, index) => (
                    <React.Fragment key={index}>
                      {(processingQuote !== null && processingQuote.company_id === 1 && quote.company_id === 1) ? (
                        <RiskCard
                          quote={quote}
                          type={type}
                          selectQuotation={selectQuotation}
                          requestInfo={requestInfo}
                        />
                      ) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                    </React.Fragment>
                  )) : ''}

                  {type.id === 'tutela_legale' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote, index) => (
                    <React.Fragment key={index}>
                      {quote.company_id === 15 ? (
                        <AragCard
                          quotation={quote}
                          selectQuotation={selectQuote}
                          requestInfo={requestInfo}
                          type={type}
                        />
                      ) : (
                        <RiskCard
                          quote={quote}
                          type={type}
                          selectQuotation={selectQuote}
                          requestInfo={requestInfo}
                        />
                      )}
                    </React.Fragment>

                  )) : ''}

                  {type.id === 'infortuni_conducente' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote, index) => (
                    <React.Fragment key={index}>
                      {(processingQuote !== null && processingQuote.company_id === 1 && quote.company_id === 1) ? (
                        <RiskCard
                          quote={quote}
                          type={type}
                          selectQuotation={selectQuote}
                          requestInfo={requestInfo}
                        />
                      ) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                    </React.Fragment>
                  )) : ''}

                  {type.id === 'rivalsa' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote, index) => (
                    <React.Fragment key={index}>
                      {(processingQuote !== null && processingQuote.company_id === 1 && quote.company_id === 1) ? (
                        <RiskCard
                          quote={quote}
                          type={type}
                          selectQuotation={selectQuote}
                          requestInfo={requestInfo}
                        />
                      ) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                    </React.Fragment>
                  )) : ''}

                  {type.id === 'cristalli' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote, index) => (
                    <React.Fragment key={index}>
                      {(processingQuote !== null && processingQuote.company_id === 1 && quote.company_id === 1) ? (
                        <RiskCard
                          quote={quote}
                          type={type}
                          selectQuotation={selectQuote}
                          requestInfo={requestInfo}
                        />
                      ) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                    </React.Fragment>
                  )) : ''}

                  {(quotations && quotations[type.id] && quotations[type.id].length === 0) && <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                </div>
              </>
            )}

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger px-4" data-bs-dismiss="modal">Chiudi</button>
          </div>
        </div>
      </div>
    </RespCivileModal>
  );
}

export default ModalQuotes;
